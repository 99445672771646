// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// @import "material-icons/iconfont/material-icons.css";
@import "node_modules/material-icons/iconfont/material-icons.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@import "_variables";

@tailwind base;
@tailwind components;
@tailwind utilities;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($alpine-monitoring-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src:
		/* from https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0KExQ.woff2 */
    url("./assets/fonts/PT_Sans_400.woff2") format("woff2"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0KEww.woff */
      url("./assets/fonts/PT_Sans_400.woff") format("woff"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0KEwQ.eot */
      url("./assets/fonts/PT_Sans_400.eot?#iefix") format("embedded-opentype"),
    /* from https://fonts.gstatic.com/l/font?kit=jizaRExUiTo99u79D0KEwg&skey=fd0b32f0f3e6a1fc&v=v12#PTSans */
      url("./assets/fonts/PT_Sans_400.svg#PTSans") format("svg"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0KEwA.ttf */
      url("./assets/fonts/PT_Sans_400.ttf") format("truetype");
}
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  src:
		/* from https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0e0x8mI.woff2 */
    url("/assets/fonts/PT_Sans_400italic.woff2") format("woff2"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0e0x8mO.woff */
      url("/assets/fonts/PT_Sans_400italic.woff") format("woff"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0e0x8mM.eot */
      url("/assets/fonts/PT_Sans_400italic.eot?#iefix")
      format("embedded-opentype"),
    /* from https://fonts.gstatic.com/l/font?kit=jizYRExUiTo99u79D0e0x8mP&skey=cef7fd018888390e&v=v12#PTSans */
      url("/assets/fonts/PT_Sans_400italic.svg#PTSans") format("svg"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0e0x8mN.ttf */
      url("/assets/fonts/PT_Sans_400italic.ttf") format("truetype");
}
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src:
		/* from https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0O6tLQ.woff2 */
    url("/assets/fonts/PT_Sans_700.woff2") format("woff2"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0O6tKw.woff */
      url("/assets/fonts/PT_Sans_700.woff") format("woff"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0O6tKQ.eot */
      url("/assets/fonts/PT_Sans_700.eot?#iefix") format("embedded-opentype"),
    /* from https://fonts.gstatic.com/l/font?kit=jizfRExUiTo99u79B_mh0O6tKg&skey=a45e760d002c9b10&v=v12#PTSans */
      url("/assets/fonts/PT_Sans_700.svg#PTSans") format("svg"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0O6tKA.ttf */
      url("/assets/fonts/PT_Sans_700.ttf") format("truetype");
}
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  src:
		/* from https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOydLxUd.woff2 */
    url("/assets/fonts/PT_Sans_700italic.woff2") format("woff2"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOydLxUb.woff */
      url("/assets/fonts/PT_Sans_700italic.woff") format("woff"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOydLxUZ.eot */
      url("/assets/fonts/PT_Sans_700italic.eot?#iefix")
      format("embedded-opentype"),
    /* from https://fonts.gstatic.com/l/font?kit=jizdRExUiTo99u79D0e8fOydLxUa&skey=2b38b88a40bdc8ec&v=v12#PTSans */
      url("/assets/fonts/PT_Sans_700italic.svg#PTSans") format("svg"),
    /* from https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOydLxUY.ttf */
      url("/assets/fonts/PT_Sans_700italic.ttf") format("truetype");
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
}

mat-card {
  max-width: 1200px;
}

.button-text {
  font-weight: 700;
  text-transform: uppercase;
  color: mat.get-color-from-palette($trigonos-primary, 700, 1);
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

mat-dialog-content {
  width: 80vw;

  @include media-breakpoint-down(xs) {
    width: 95vw;
  }

  @include media-breakpoint-up(lg) {
    width: 65vw;
  }
}

.user-selection-group {
  border: 1px solid;
  border-radius: 4px;
  padding: 15px;
  border-color: rgba(0, 0, 0, 0.5);
  margin: 1rem 0 1rem 0;
}

.h-0 {
  height: 0px;
  display: none !important;
}
